.App {
  text-align: center;
  display: flex;
}

.App-section {
  flex: 1;

  label {
    text-align: left;
    display: block;
    margin-bottom: 8px;
  }

  input {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    height: 32px;
    border-radius: 4px;
  }

  form {
    padding: 24px;
  }
}

.App-promo-image {
  object-fit: cover;
  max-width: 100%;
  height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
